html, body, #root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
